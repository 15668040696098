import dayjs from 'dayjs'

import { SITE_APP } from '@/utils/constant'

const MONTH: { [key: number]: string } = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

export function fullWidthToHalfWidth(str: string) {
  if (str)
    return str.replace(/[０-９]/g, function (char) {
      const fullWidth = char.charCodeAt(0)
      const halfWidth = fullWidth - 0xfee0
      return String.fromCharCode(halfWidth)
    })
}

export function formatCurrency(amount: number, currencyCode = 'USD') {
  return amount.toLocaleString('ja-JP', {
    style: 'decimal',
    currency: currencyCode
  })
}

const SITE = process.env.VUE_APP_SITE

export function replaceUrl(url: string) {
  return SITE === SITE_APP.ADMIN ? `/admin${url}` : url
}

export function getUniqueID() {
  return Math.floor(Date.now() + Math.random())
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>

  return function (...args: Parameters<T>) {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

export function convertTel(data: string) {
  if (!data) return
  if (data?.length < 10) return data
  if (data.length == 10)
    return data.slice(0, 2) + '-' + data.slice(2, 6) + '-' + data.slice(6, 11)
  return data.slice(0, 3) + '-' + data.slice(3, 7) + '-' + data.slice(7, 11)
}

export function convertDate(date: string) {
  if (!date) return
  return (
    '<span>' +
    dayjs(date).format('YYYY') +
    '年' +
    dayjs(date).format('MM') +
    '月' +
    dayjs(date).format('DD') +
    '日 </span><span>' +
    dayjs(date).format(' HH:mm') +
    'に</span>'
  )
}

export function convertDateName(data: string) {
  if (!data) return
  const time = dayjs(data).format('HH:mm')
  const date = dayjs(data).format('DD')
  const year = dayjs(data).format('YYYY')
  const month = dayjs(data).format('MM')
  const monthName = month ? MONTH[+month] : ''
  return 'at ' + time + ' on ' + monthName + ' ' + date + ',' + year + '.'
}
