import Cookie from 'js-cookie'

import { useSidebar } from '@/composable/useSidebar'
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_ME } from '@/constants/common'
const { isOpen } = useSidebar()

export const setAccessToken = (token: string, expires_in: number) => {
  const now = new Date()
  const time = now.getTime()
  const expireTime = time + 1000 * expires_in
  now.setTime(expireTime)

  // setToken
  return Cookie.set(ACCESS_TOKEN, token, {
    expires: now,
    secure: true
  })
  // return Cookie.set(ACCESS_TOKEN, token)
}

export const setUser = (data: any) => {
  return Cookie.set(USER_ME, data)
}

export const setRefreshToken = (token: string, expires_in: number) => {
  const now = new Date()
  const time = now.getTime()
  const expireTime = time + 1000 * expires_in
  now.setTime(expireTime)

  // setToken
  return Cookie.set(REFRESH_TOKEN, token, {
    expires: now,
    secure: true
  })
  // return Cookie.set(REFRESH_TOKEN, token)
}

export const getAccessToken = () => {
  return Cookie.get(ACCESS_TOKEN)
}

export const removeToken = () => {
  isOpen.value = false
  Cookie.remove(ACCESS_TOKEN)
  Cookie.remove(REFRESH_TOKEN)
  Cookie.remove(USER_ME)
  localStorage.removeItem('me')
  localStorage.removeItem('MENU_TYPE')
  return localStorage.removeItem('user_type')
}
