import { RouteRecordRaw } from 'vue-router'

import { SidebarType } from '@/types/home.type'

const getView = (path: string) => {
  return () => import(`@/views/${path}`)
}

export const routes: Array<RouteRecordRaw> = [
  // auth
  {
    path: '/auth',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Auth Login',
        component: getView('auth/LoginView.vue'),
        meta: {
          title: 'ログイン'
        }
      },
      {
        path: 'forgot-password',
        name: 'Auth Forgot Password',
        component: getView('auth/ForgotPasswordView.vue'),
        meta: {
          title: 'パスワードリセット'
        }
      },
      {
        path: 'reset-password/mail/complete',
        name: 'Auth Forgot Password Complete',
        component: getView('auth/ForgotPasswordCompleteView.vue'),
        meta: {
          title: 'パスワードリセット'
        }
      },
      {
        path: 'reset-password',
        name: 'Auth Reset Password',
        component: getView('auth/ResetPasswordView.vue'),
        meta: {
          title: 'パスワード再設定'
        }
      },
      {
        path: 'reset-password/complete',
        name: 'Reset Password Complete',
        component: getView('auth/ResetPasswordCompleteView.vue'),
        meta: {
          title: 'パスワード再設定'
        }
      },
      {
        path: 'sent-mail-register-member',
        name: 'Register member',
        component: getView('auth/RegisterMember.vue'),
        meta: {
          title: 'アカウント登録'
        }
      },
      {
        path: 'sent-mail-register-member/complete',
        name: 'Sent mail register member complete',
        component: getView('auth/ForgotPasswordCompleteView.vue'),
        meta: {
          title: 'アカウント登録'
        }
      },
      {
        path: 'register-info-member',
        name: 'Register info member',
        component: getView('auth/RegisterInfoMember.vue'),
        meta: {
          title: 'アカウント登録'
        }
      },
      {
        path: 'register-info-member/complete',
        name: 'Register info member complete',
        component: getView('auth/ResetPasswordCompleteView.vue'),
        meta: {
          title: 'アカウント登録'
        }
      },
      {
        path: 'registration-visit-destination',
        name: 'Registration Visit Destination',
        component: getView('auth/RegistrationVisitDestination.vue'),
        meta: {
          title: '入館受付情報登録（訪問先選択）'
        }
      },
      {
        path: 'registration-visit-now',
        name: 'Registration Visit Destination Now',
        component: getView('auth/RegistrationVisitNow.vue'),
        meta: {
          title: '入館受付情報登録（訪問先選択）'
        }
      },
      {
        path: 'registration-visit-complete/:uuid',
        name: 'Registration Visit Result',
        component: getView('auth/RegisterVisitResult.vue'),
        meta: {
          title: '入館受付情報表示（テナント予約）'
        }
      }
    ]
  },

  // Partner router

  {
    path: '/',
    component: () => import('@/layouts/HomeLayout.vue'),
    children: [
      // B
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: getView('home/DashboardView.vue'),
        meta: {
          auth: true,
          title: 'ダッシュボード',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'request-dispatcher',
        name: 'Request',
        children: [
          {
            path: '',
            name: 'Request Dispatcher',
            component: getView('RequestDispatcher/OverviewRequest.vue'),
            meta: {
              auth: true,
              title: '各種申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'search',
            name: 'Request Dispatcher Search',
            component: getView('RequestDispatcher/SearchRequest.vue'),
            meta: {
              auth: true,
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'important-notification-search',
        name: 'Important Notification SideBar',
        children: [
          {
            path: '',
            name: 'Important Notification SideBar Search',
            component: getView('RequestDispatcher/SearchRequest.vue'),
            meta: {
              auth: true,
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'work-application',
        name: 'Work application',
        children: [
          // {
          //   path: '',
          //   name: 'Work application List',
          //   component: getView('WorkApplication/WorkApplicationList.vue'),
          //   meta: {
          //     auth: true,
          //     title: '作業申請',
          //     fullWidth: true,
          //     sidebar: SidebarType.HomePage
          //   }
          // },
          {
            path: 'create',
            name: 'Work application Create',
            component: getView('Request/RequestCreateView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':requestId(\\d+)',
            name: 'Detail Request',
            component: getView('Request/RequestDetailView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'copy/:requestId(\\d+)',
            name: 'Copy Request',
            component: getView('Request/RequestEditView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:requestId(\\d+)',
            name: 'Edit Request',
            component: getView('Request/RequestEditView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'approve/:requestId(\\d+)',
            name: 'User Approve',
            component: getView('Request/RequestApproveView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'other-applications',
        name: 'Other applications',
        children: [
          // {
          //   path: '',
          //   name: 'Other applications List',
          //   component: getView('OtherApplication/OtherApplicationList.vue'),
          //   meta: {
          //     auth: true,
          //     title: 'その他の申請',
          //     fullWidth: true,
          //     sidebar: SidebarType.HomePage
          //   }
          // },
          {
            path: 'current-survey-request',
            name: 'Current Survey',
            children: [
              {
                path: '',
                name: 'Current Survey Create',
                component: getView('OtherApplication/CurrentSurveyRequest.vue'),
                meta: {
                  auth: true,
                  title: '現況調査票',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Current Survey Detail',
                component: getView('OtherApplication/CurrentSurveyRequest.vue'),
                meta: {
                  auth: true,
                  title: '現況調査票申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Current Survey Edit',
                component: getView('OtherApplication/CurrentSurveyRequest.vue'),
                meta: {
                  auth: true,
                  title: '現況調査票申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'interview-shooting-request',
            name: 'Interview Shooting',
            children: [
              {
                path: '',
                name: 'Interview Shooting Create',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Interview Shooting Detail',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Interview Shooting Edit',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Interview Shooting Copy',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Interview Shooting Approve',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'building-photo-request',
            name: 'Building Photo',
            children: [
              {
                path: '',
                name: 'Building Photo Create',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Building Photo Detail',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Building Photo Copy',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Building Photo Edit',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'advertisement-request',
            name: 'Advertisement ',
            children: [
              {
                path: '',
                name: 'Advertisement Create ',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Advertisement Detail ',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Advertisement Copy',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Advertisement Edit',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },

          {
            path: 'building-staff-survey',
            name: 'Building Staff Survey Form',
            children: [
              {
                path: '',
                name: 'Building Staff Create',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Building Staff Detail',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Building Staff Copy',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Building Staff Edit',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'security-card-request',
            name: 'Security Card',
            children: [
              {
                path: '',
                name: 'Security Card Create',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード発行依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Security Card Detail',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード発行依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Security Card Edit',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード発行依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Security Card Copy',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード発行',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'change-security-card-request',
            name: 'Change Security Card',
            children: [
              {
                path: '',
                name: 'Change Security Card Create',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード設定変更依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Change Security Card Detail',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード設定変更依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Change Security Card Edit',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード設定変更依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Change Security Card Copy',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'セキュリティカード設定変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'change-security-card-reader',
            name: 'Change Security Card Reader',
            children: [
              {
                path: '',
                name: 'Change Security Card Reader Create',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカードリーダー設定変更依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Change Security Card Reader Detail',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカードリーダー設定変更依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Change Security Card Reader Edit',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカードリーダー設定変更依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Change Security Card Reader Copy',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'カードリーダー設定変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'remove-security-card-request',
            name: 'Remove Security Card',
            children: [
              {
                path: '',
                name: 'Remove Security Card Create',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード抹消依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Remove Security Card Detail',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード抹消依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Remove Security Card Edit',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード抹消依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Remove Security Card Copy',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'セキュリティカード抹消',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'security-card-log-data-request',
            name: 'Security Card Log Data',
            children: [
              {
                path: '',
                name: 'Security Card Log Data Create',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード通行履歴ログデータ開示依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Security Card Log Data Detail',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード通行履歴ログデータ開示依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Security Card Log Data Edit',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード通行履歴ログデータ開示依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Security Card Log Data Copy',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード通行履歴ログ開示',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'disable-security-card',
            name: 'DeActive Security Card',
            children: [
              {
                path: '',
                name: 'DeActive Security Card Create',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'DeActive Security Card Detail',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'DeActive Security Card Edit',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'DeActive Security Card Copy',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'DeActive Security Card Approve',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'open-security-card/:requestId(\\d+)',
            name: 'Open Security Card',
            children: [
              {
                path: '',
                name: 'Open Security Card Create',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'detail',
                name: 'Open Security Card Detail',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit',
                name: 'Open Security Card Edit',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve',
                name: 'Open Security Card Approve',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'change-work-time',
            name: 'Change Work Time',
            children: [
              {
                path: '',
                name: 'Change Work Time Create',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Change Work Time Create Detail',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Change Work Time Create Edit',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Change Work Time Create Copy',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'open-security-card-reader-log-data',
            name: 'Open Security Card Reader',
            children: [
              {
                path: '',
                name: 'Open Security Card Reader Create',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'カードリーダー通行履歴ログデータ開示依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Open Security Card Reader Detail',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title:
                    'カードリーダー通行履歴ログデータ開示依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Open Security Card Reader Edit',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title:
                    'カードリーダー通行履歴ログデータ開示依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Open Security Card Reader Copy',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'fire-equipment-request',
            name: 'Fire Equipment',
            children: [
              {
                path: '',
                name: 'Fire Equipment Create',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Fire Equipment Detail',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Fire Equipment Edit',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Fire Equipment Copy',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'area-sign-request',
            name: 'Area Sign',
            children: [
              {
                path: '',
                name: 'Area Sign Create',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Area Sign Detail',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Area Sign Edit',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Area Sign Copy',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'air-condition-request',
            name: 'Air Condition',
            children: [
              {
                path: '',
                name: 'Air Condition Create',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Air Condition Detail',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Air Condition Edit',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Air Condition Copy',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Air Condition Approve',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'parking-ticket-request',
            name: 'Parking Ticket',
            children: [
              {
                path: '',
                name: 'Parking Ticket Create',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Parking Ticket Detail',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Parking Ticket Edit',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Parking Ticket Copy',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'event-approval',
            name: 'Event Approval',
            children: [
              {
                path: '',
                name: 'Event Approval Create',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Event Approval Detail',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Event Approval Edit',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Event Approval Copy',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Event Approval Approve',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'important-notification/:requestId(\\d+)',
            name: 'Important Notification detail',
            component: getView('OtherApplication/ImportantNotification.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'important-notification/approve/:requestId(\\d+)',
            name: 'Important Notification Approve',
            component: getView('OtherApplication/ImportantNotification.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'arrangement',
            name: 'Arrangement',
            children: [
              {
                path: '',
                name: 'Arrangement Create',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Arrangement Detail',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Arrangement Edit',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Arrangement Copy',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'safe-parking',
            name: 'Safe Parking',
            children: [
              {
                path: '',
                name: 'Safe Parking Create',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Safe Parking Detail',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Safe Parking Edit',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Safe Parking Copy',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          }
        ]
      },
      {
        path: 'tenant-management',
        name: 'Tenant management',
        children: [
          {
            path: '',
            name: 'Tenant management List',
            component: getView('TenantManagement/TenantCompany.vue'),
            meta: {
              auth: true,
              title: '会社情報',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'user-management/create',
            name: 'Tenant Create Own user management',
            component: getView('OwnUserManagement/TenantUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー登録',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'user-management/edit/:accountId(\\d+)',
            name: 'Create Edit Own user management',
            component: getView('OwnUserManagement/TenantUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'user-management/:accountId(\\d+)',
            name: 'Detail Own user management',
            component: getView('OwnUserManagement/TenantUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'faq-public',
        name: 'FAQ',
        children: [
          {
            path: '',
            name: 'FAQ Public List',
            component: getView('faq/faqListPublic.vue'),
            meta: {
              auth: true,
              fullWidth: true,
              title: 'よくあるご質問',
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':faqId(\\d+)',
            name: 'FAQ Public Detail',
            component: getView('faq/faqPublicDetail.vue'),
            meta: {
              auth: true,
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'download-form-request',
        name: 'Download Request Form ',
        children: [
          {
            path: '',
            name: 'Download Request Form List ',
            component: getView('Download/DownloadFormRequest.vue'),
            meta: {
              auth: true,
              title: '申請書ダウンロード',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'download-manually',
        name: 'Download Manually',
        component: getView('Download/DownloadManually.vue'),
        meta: {
          auth: true,
          title: 'マニュアルダウンロード',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'qa-form',
        name: 'QA Form',
        component: getView('QA/QAForm.vue'),
        meta: {
          auth: true,
          title: 'お問い合わせ',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },

      {
        path: 'construction-management',
        name: 'Construction management',
        component: getView(
          'ConstructionManagement/ConstructionManagementList.vue'
        ),
        meta: {
          auth: true,
          title: '施工管理',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'own-user-management',
        name: 'Own user management',
        children: [
          {
            path: '',
            name: 'Own user management List',
            component: getView('OwnUserManagement/OwnUserManagementList.vue'),
            meta: {
              auth: true,
              title: '自社ユーザー管理',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'create',
            name: 'Own user management Create',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー登録',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:accountId(\\d+)',
            name: 'Own user management Edit',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':accountId(\\d+)',
            name: 'Own user management Detail',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },

      {
        path: 'cms',
        name: 'CMS',
        component: getView('CMS/CMSList.vue'),
        meta: {
          auth: true,
          title: 'CMS',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'request',
        name: 'Form Request',
        children: [
          // B
          {
            path: 'create',
            name: 'Form Request Create',
            component: getView('Request/RequestCreateView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':requestId(\\d+)',
            name: 'Request Detail',
            component: getView('Request/RequestDetailView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:requestId(\\d+)',
            name: 'Edit Detail',
            component: getView('Request/RequestEditView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'news',
        name: 'News',
        children: [
          {
            path: '',
            name: 'New List',
            component: getView('Notifications/NotificationPublicList.vue'),
            meta: {
              auth: true,
              title: 'ニュース一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':notificationId(\\d+)',
            name: 'New Detail',
            component: getView('Notifications/NotificationDetail.vue'),
            meta: {
              auth: true,
              title: '館内〇〇のお知らせ',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'general-information',
        name: 'General Information',
        children: [
          {
            path: '',
            name: 'General Information List',
            component: getView('Notifications/NotificationPublicList.vue'),
            meta: {
              auth: true,
              title: '全館案内一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':notificationId(\\d+)',
            name: 'General Information Detail',
            component: getView('Notifications/NotificationDetail.vue'),
            meta: {
              auth: true,
              title: '館内〇〇のお知らせ',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'monthly-billing-list',
        name: 'Monthly billing list',
        component: getView('MonthlyBilling/MonthlyBillingList.vue'),
        meta: {
          auth: true,
          title: '月次請求一覧',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'my-account',
        name: 'My Account',
        component: getView('home/MyAccount.vue'),
        meta: {
          auth: true,
          title: 'マイアカウント',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'parking-lot-holiday-settings',
        name: 'Parking Lot Holiday Settings',
        component: getView('ParkingLotHoliday/ParkingLotHolidaySetting.vue'),
        meta: {
          auth: true,
          title: '駐車場休日設定',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'list-request-form',
        name: 'List Request Form',
        component: getView('Request/ListRequestForm.vue'),
        meta: {
          auth: true,
          title: '',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'visit-reservation-tenant',
        name: 'Visit Reservation Tenant',
        component: getView('AdmissionManagement/VisitReservationTenant.vue'),
        meta: {
          auth: true,
          title: '来訪予約（テナント）',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'card-information-list',
        name: 'Card Information List',
        component: getView('AdmissionManagement/CardInfomationList.vue'),
        meta: {
          auth: true,
          title: 'カード情報一覧',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'card-log',
        name: 'Card Log',
        component: getView('AdmissionManagement/CardLog.vue'),
        meta: {
          auth: true,
          title: 'カードログ参照',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'list-reception',
        name: 'List Reception',
        component: getView('AdmissionManagement/ListReception.vue'),
        meta: {
          auth: true,
          title: '入館受付',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'aggregation',
        name: 'Aggregation',
        component: getView('Aggregation/AggregationForm.vue'),
        meta: {
          auth: true,
          title: '集計',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'admission-information-list',
        name: 'Admission Information List',
        component: getView('AdmissionManagement/AdmissionInformationList.vue'),
        meta: {
          auth: true,
          title: '入館情報検索',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'waiting-approval-list',
        name: 'Waiting Approval List',
        component: getView(
          'AdmissionManagement/AdmissionInformationListApprove.vue'
        ),
        meta: {
          auth: true,
          title: '入館情報検索',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'change-card-status-bulk',
        name: 'Change card status bulk',
        component: getView('AdmissionManagement/ChangeCardStatusBulk.vue'),
        meta: {
          auth: true,
          title: 'カードステータス変更',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'checking-tightening-process',
        name: 'Checking tightening process',
        component: getView('AdmissionManagement/CheckingTighteningProcess.vue'),
        meta: {
          auth: true,
          title: '',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'admission-details/:id(\\d+)',
        name: 'Admission details',
        component: getView('AdmissionManagement/AdmissionDetails.vue'),
        meta: {
          auth: true,
          title: '入館情報詳細',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'admission-details-approval/:id(\\d+)',
        name: 'Admission details approval',
        component: getView('AdmissionManagement/AdmissionDetailsApproval.vue'),
        meta: {
          auth: true,
          title: '入館情報詳細（承認）',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      }
    ]
  },
  {
    path: '/components',
    component: getView('ComponentsView.vue')
  },
  {
    path: '/sitemap',
    component: () => import('../views/SiteMap.vue')
  },
  {
    path: '/404',
    name: '404 page',
    component: getView('Page404View.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/403',
    name: '403 page',
    component: getView('Page403View.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/system-error',
    name: 'system error',
    component: getView('SystemErrorView.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 Not Found',
    component: getView('Page404View.vue'),
    meta: {
      auth: false
    }
  }
]
