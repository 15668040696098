import { replaceUrl } from '@/composable/common'
import { PERMISSION, PHASE_TYPE } from '@/constants/common'
import {
  ADMIN_REGISTRATION_VISIT_DESTINATION,
  ADMIN_REGISTRATION_VISIT_DESTINATION_NOW,
  REGISTER_INFO_MEMBER,
  REGISTER_INFO_MEMBER_COMPETE,
  REGISTER_MEMBER,
  REGISTER_MEMBER_COMPLETE,
  REGISTRATION_VISIT_COMPLETE,
  REGISTRATION_VISIT_DESTINATION,
  REGISTRATION_VISIT_DESTINATION_NOW,
  RESET_MAIL_COMPLETE,
  RESET_MAIL_COMPLETE_ADMIN,
  RESET_PASSWORD,
  RESET_PASSWORD_ADMIN,
  RESET_PASSWORD_COMPLETE,
  RESET_PASSWORD_COMPLETE_ADMIN,
  ROUTER_ADMIN,
  ROUTER_AUTH,
  ROUTER_WEB,
  SCREEN_FORGOT_PASSWORD,
  SCREEN_FORGOT_PASSWORD_ADMIN,
  SCREEN_HOME,
  SCREEN_HOME_ADMIN,
  SCREEN_LOGIN,
  SCREEN_LOGIN_ADMIN,
  SYSTEM_ERROR
} from '@/constants/screen.const'
import router from '@/router'
import { SITE_APP } from '@/utils/constant'
import { getAccessToken } from '@/utils/token'

// eslint-disable-next-line
export default async function auth(to: any, from: any, next: any) {
  const token = getAccessToken()
  const SITE = process.env.VUE_APP_SITE
  console.log('to:', to.path)
  if (localStorage.getItem('me')) {
    const userByMe = JSON.parse(localStorage.getItem('me') || '')
    if (userByMe?.company?.company_type_mst?.id) {
      switch (+userByMe?.company?.company_type_mst?.id) {
        case PERMISSION.TEKKO_BUILDING:
        case PERMISSION.TCS:
          if (to.path?.includes(ROUTER_ADMIN.waitingApprovalList)) {
            return router.push('/403')
          }
          if (to.path?.includes(ROUTER_ADMIN.visitReservationTenant)) {
            return router.push('/403')
          }
          break
        case PERMISSION.PARTNERS_SERCURITY:
        case PERMISSION.PARTNERS_RECEPTIONIST:
          if (
            to.path?.includes(ROUTER_ADMIN.waitingApprovalList) ||
            to.path?.includes(ROUTER_ADMIN.visitReservationTenant)
          ) {
            return router.push('/403')
          }
          break
        case PERMISSION.TENANTS_OFFICE:
          if (
            (to.path?.includes(ROUTER_ADMIN.listScheduledAdmissions) ||
              to.path?.includes(ROUTER_ADMIN.cardInformationList) ||
              to.path?.includes(ROUTER_ADMIN.cardLog) ||
              to.path?.includes(ROUTER_ADMIN.changeCardStatus) ||
              to.path?.includes(ROUTER_ADMIN.aggregation) ||
              to.path?.includes(ROUTER_ADMIN.checkingTightening)) &&
            userByMe?.detail?.type != 1
          ) {
            return router.push('/403')
          } else if (
            (to.path?.includes(ROUTER_ADMIN.waitingApprovalList) ||
              to.path?.includes(ROUTER_ADMIN.listScheduledAdmissions) ||
              to.path?.includes(ROUTER_ADMIN.cardInformationList) ||
              to.path?.includes(ROUTER_ADMIN.cardLog) ||
              to.path?.includes(ROUTER_ADMIN.changeCardStatus) ||
              to.path?.includes(ROUTER_ADMIN.aggregation) ||
              to.path?.includes(ROUTER_ADMIN.visitReservationTenant) ||
              to.path?.includes(ROUTER_ADMIN.checkingTightening)) &&
            (userByMe?.detail?.type == 1 ||
              (!userByMe?.can_access_phase_2 && userByMe?.detail?.type != 1))
          )
            return router.push('/403')
          break
        case PERMISSION.TENANTS_STORE:
          if (
            to.path?.includes(ROUTER_WEB.tenantManagement) &&
            userByMe?.detail?.type != 1
          ) {
            return router.push('/403')
          }
          break
        default:
          if (
            to.path?.includes(ROUTER_ADMIN.waitingApprovalList) ||
            to.path?.includes(ROUTER_ADMIN.listScheduledAdmissions) ||
            to.path?.includes(ROUTER_ADMIN.cardInformationList) ||
            to.path?.includes(ROUTER_ADMIN.cardLog) ||
            to.path?.includes(ROUTER_ADMIN.changeCardStatus) ||
            to.path?.includes(ROUTER_ADMIN.aggregation) ||
            to.path?.includes(ROUTER_ADMIN.visitReservationTenant) ||
            to.path?.includes(ROUTER_ADMIN.checkingTightening)
          ) {
            return router.push('/403')
          }
          break
      }
    }
    if (
      (to.path?.includes(ROUTER_ADMIN.account) ||
        to.path?.includes(replaceUrl(ROUTER_WEB.tenantManagement))) &&
      SITE === SITE_APP.USER &&
      localStorage.getItem('MENU_TYPE') === `${PHASE_TYPE.PHASE_2}`
    ) {
      return router.push('/403')
    }
  }

  if (
    (to.path === ROUTER_ADMIN.workApplication ||
      to.path === ROUTER_ADMIN.otherApplications) &&
    token
  ) {
    return router.push('/404')
  }

  if (SITE === SITE_APP.USER) {
    if (to.path === ROUTER_AUTH.login && token) {
      return router.push(ROUTER_WEB.dashboard)
    }

    if (to.path === ROUTER_WEB.home && token) {
      return router.push(ROUTER_WEB.dashboard)
    }

    if (to.path === ROUTER_WEB.dashboard && !token) {
      return next({ path: ROUTER_AUTH.login, query: { redirect: to.fullPath } })
    }

    if (to.path === ROUTER_AUTH.login) {
      if (!token) {
        return next()
      }
      return next({ name: SCREEN_HOME })
    }

    if (
      [
        REGISTER_MEMBER,
        REGISTER_MEMBER_COMPLETE,
        REGISTER_INFO_MEMBER,
        REGISTER_INFO_MEMBER_COMPETE,
        REGISTRATION_VISIT_COMPLETE,
        SYSTEM_ERROR
      ].includes(to.name)
    ) {
      return next()
    }

    if (
      [
        RESET_PASSWORD_COMPLETE,
        RESET_PASSWORD,
        RESET_MAIL_COMPLETE,
        SCREEN_FORGOT_PASSWORD,
        REGISTRATION_VISIT_DESTINATION,
        REGISTRATION_VISIT_DESTINATION_NOW,
        REGISTER_MEMBER,
        REGISTER_MEMBER_COMPLETE,
        REGISTER_INFO_MEMBER,
        REGISTER_INFO_MEMBER_COMPETE,
        SYSTEM_ERROR
      ].includes(to.name)
    ) {
      if (token) {
        if (
          to.name === REGISTRATION_VISIT_DESTINATION ||
          to.name === REGISTRATION_VISIT_DESTINATION_NOW
        ) {
          return router.push('/403')
        }
        return next({ name: SCREEN_HOME })
      }
      return next()
    }

    if (![SCREEN_LOGIN].includes(to.name)) {
      if (!token) {
        if (
          to.name === REGISTRATION_VISIT_DESTINATION ||
          to.name === REGISTRATION_VISIT_DESTINATION_NOW
        ) {
          return next()
        }
        return next({
          path: ROUTER_AUTH.login,
          query: { redirect: to.fullPath }
        })
      }
      return next()
    }
  } else if (SITE === SITE_APP.ADMIN) {
    if (to.path === ROUTER_AUTH.login && token) {
      return router.push(ROUTER_ADMIN.dashboard)
    }

    if (localStorage.getItem('me')) {
      const userByMe = JSON.parse(localStorage.getItem('me') || '')
      if (
        (to.path == ROUTER_ADMIN.account ||
          to.path == ROUTER_ADMIN.accountCreate ||
          to.path == ROUTER_ADMIN.account + `/${to.params?.accountId}` ||
          to.path == ROUTER_ADMIN.faq ||
          to.path == ROUTER_ADMIN.faqCreate ||
          to.path == ROUTER_ADMIN.faq + `/${to.params?.faqId}` ||
          to.path == ROUTER_ADMIN.general ||
          to.path == ROUTER_ADMIN.news ||
          to.path == ROUTER_ADMIN.generalCreate ||
          to.path == ROUTER_ADMIN.newsCreate ||
          to.path == ROUTER_ADMIN.news + `/${to.params?.notificationId}` ||
          to.path == ROUTER_ADMIN.general + `/${to.params?.notificationId}`) &&
        +userByMe?.company?.company_type_mst?.id == 15
      ) {
        return router.push('/403')
      }
    }

    if (to.path === ROUTER_ADMIN.home && token) {
      return router.push(ROUTER_ADMIN.dashboard)
    }

    if (to.path === ROUTER_ADMIN.home && !token) {
      return next({ path: ROUTER_AUTH.login, query: { redirect: to.fullPath } })
      // return router.push(ROUTER_AUTH.login)
    }

    if (to.path === ROUTER_AUTH.login) {
      if (!token) {
        return next()
      }
      return next({ name: SCREEN_HOME_ADMIN })
    }

    if (
      [
        RESET_PASSWORD_COMPLETE_ADMIN,
        RESET_PASSWORD_ADMIN,
        RESET_MAIL_COMPLETE_ADMIN,
        SCREEN_FORGOT_PASSWORD_ADMIN,
        ADMIN_REGISTRATION_VISIT_DESTINATION,
        ADMIN_REGISTRATION_VISIT_DESTINATION_NOW,
        REGISTRATION_VISIT_COMPLETE
      ].includes(to.name)
    ) {
      if (token) {
        if (
          to.name === ADMIN_REGISTRATION_VISIT_DESTINATION ||
          to.name === ADMIN_REGISTRATION_VISIT_DESTINATION_NOW
        ) {
          return router.push('/403')
        }
        return next({ name: SCREEN_HOME_ADMIN })
      }
      return next()
    }

    if (![SCREEN_LOGIN_ADMIN].includes(to.name)) {
      if (!token) {
        if (
          to.name === ADMIN_REGISTRATION_VISIT_DESTINATION ||
          to.name === ADMIN_REGISTRATION_VISIT_DESTINATION_NOW
        ) {
          return next()
        }
        return next({
          path: ROUTER_AUTH.login,
          query: { redirect: to.fullPath }
        })
      }
      if (to.path === '/') {
        return next({ name: SCREEN_HOME_ADMIN })
      }
      return next()
    }
  } else {
    return next()
  }
}
